import React, {useEffect, useLayoutEffect, useState} from "react";
import Page from "../components/page";
import {OrangeLogo, OrangeLogoFull} from "../assets/OrangeLogo";
import {HomePageSignUp} from "../components/home-page-sign-up";
import {Link} from "gatsby";
import {LinkedInIcon, TwitterIcon} from "../assets/SocialIcons";


const config = {
    page_title: 'Organize your Community on Unaty'
};

const main_text = `
Hey, let’s talk about community software.

It’s 2023, and it’s still really hard to organize a community. Leaders struggle with managing member data, communication, coordinating events, and most of all creating the magical experience your members deserve.

Clunky all-in-one software, apps designed for businesses, or social media platforms that aren't built for communities just don't cut it.

Leaders should have tools that make community organizing a joy, not a hassle. Members should feel valued, supported, and heard. Together, they should be able to focus on creating meaningful connections to drive impactful change.

Community software deserves a rethink. A new approach designed for modern communities with members around the world. A tool that always puts members first and is flexible enough to enable leaders to build the exact tool their community needs.

That’s why we built Orange, a platform purpose-built for modern community organizing. Orange helps leaders build community, without the busywork.

Orange is our take on communities and we’re excited to share it with you. Coming October 2023 to the web.

- Ramin, Fabian, and Sean
`;

const try_text = `
Want to try it? Sign up for early access, we'd to hear from you.
`;
const d = new Date();
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function OrangeFooter() {
    return <div>
        <div className="pt-20">
            <Link to="/" className="py-8 flex justify-center">
                <OrangeLogoFull width={200}/>
            </Link>
            <p className="text-2xl text-center whitespace-pre-line">
                We’re on a mission to increase the membership, leadership, and impact of communities around the
                world.
            </p>
        </div>
        <div className="pt-20 text-center space-y-6">
            <div className="text-center">
                <p className="italic text-gray-600 text-base">
                    Build community, without the busywork.
                </p>
            </div>
            <div className="text-sm">
                <Link to="/imprint"
                      className="text-gray-600 underline hover:text-gray-800 cursor-pointer">Imprint</Link>
            </div>
            <div className="text-gray-600 text-sm">
                © Unaty Technologies GmbH<br/>
                Enjoy the rest of your {days[d.getDay()]}!
            </div>
            <div className="flex justify-center gap-6 items-center">
                <a href="https://twitter.com/unatyde" rel="nofollow noreferrer" className="cursor-pointer">
                    <TwitterIcon/>
                </a>
                <a href="https://twitter.com/unatyde" rel="nofollow noreferrer" className="cursor-pointer">
                    <LinkedInIcon/>
                </a>
            </div>
        </div>
    </div>
}

export function OrangeHeader() {
    return  <div className=" flex justify-center">
        <Link to="/" className="" style={{paddingTop: "10vh", paddingBottom: "6vh"}}>
            <OrangeLogo width={56}/>
        </Link>
    </div>
}

export function OrangeSignUpBlock() {
    return <div className="pt-20">
        <p className="text-xl pb-4 font-medium leading-relaxed whitespace-pre-line">
            {try_text}
        </p>
        <HomePageSignUp/>
    </div>
}

const NewHomePage = ({}) => {

    return <Page orange footer={false} title="Orange: Build community, without the busywork.">
        <div className="max-w-prose px-5 mx-auto pb-32">
           <OrangeHeader/>
            <div>
                <p className="text-xl font-medium leading-relaxed whitespace-pre-line">
                    {main_text}
                </p>
            </div>
            <div className="pt-20">
                <p className="text-xl pb-4 font-medium leading-relaxed whitespace-pre-line">
                    If you're curious about what we're building, here's a <a className="underline" href="/about-us#problems">list of the fundamental problems with community software</a> we're tackling. You can also get a preview of what we're building with this <a className="underline" href="/about-us#features">feature list</a>.
                </p>
            </div>
          <OrangeSignUpBlock />
           <OrangeFooter/>
        </div>
    </Page>
}

export default NewHomePage;