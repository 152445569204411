import React from 'react';

export function TwitterIcon() {
    return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.29 17.251C13.837 17.251 17.965 10.998 17.965 5.57596C17.965 5.39796 17.965 5.22096 17.953 5.04596C18.7562 4.46448 19.4493 3.74457 20 2.91996C19.2511 3.25196 18.4566 3.46972 17.643 3.56596C18.4996 3.05302 19.1408 2.24631 19.447 1.29596C18.6417 1.77384 17.7607 2.11069 16.842 2.29196C16.2234 1.63372 15.405 1.19779 14.5136 1.05166C13.6222 0.905532 12.7075 1.05736 11.9111 1.48363C11.1147 1.9099 10.4811 2.58683 10.1083 3.40962C9.7355 4.2324 9.64437 5.15514 9.849 6.03496C8.21759 5.9532 6.6216 5.52928 5.16465 4.79073C3.70769 4.05217 2.42233 3.01548 1.392 1.74796C0.867318 2.65116 0.706589 3.72038 0.942536 4.73792C1.17848 5.75547 1.79337 6.64483 2.662 7.22496C2.00926 7.20606 1.37063 7.03046 0.8 6.71296V6.76496C0.800389 7.71225 1.1284 8.63026 1.7284 9.36331C2.3284 10.0964 3.16347 10.5993 4.092 10.787C3.48781 10.9517 2.85389 10.9756 2.239 10.857C2.50116 11.6724 3.01168 12.3856 3.69913 12.8966C4.38658 13.4076 5.21657 13.6909 6.073 13.707C5.22212 14.3755 4.24779 14.8698 3.20573 15.1615C2.16367 15.4532 1.07432 15.5366 0 15.407C1.8766 16.6113 4.06019 17.2501 6.29 17.247" fill="#696969"/>
    </svg>
}

export function LinkedInIcon() {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_171_78)">
            <path d="M15.8333 0H4.16667C1.86583 0 0 1.86583 0 4.16667V15.8333C0 18.1342 1.86583 20 4.16667 20H15.8333C18.135 20 20 18.1342 20 15.8333V4.16667C20 1.86583 18.135 0 15.8333 0ZM6.66667 15.8333H4.16667V6.66667H6.66667V15.8333ZM5.41667 5.61C4.61167 5.61 3.95833 4.95167 3.95833 4.14C3.95833 3.32833 4.61167 2.67 5.41667 2.67C6.22167 2.67 6.875 3.32833 6.875 4.14C6.875 4.95167 6.2225 5.61 5.41667 5.61ZM16.6667 15.8333H14.1667V11.1633C14.1667 8.35667 10.8333 8.56917 10.8333 11.1633V15.8333H8.33333V6.66667H10.8333V8.1375C11.9967 5.9825 16.6667 5.82333 16.6667 10.2008V15.8333Z" fill="#696969"/>
        </g>
        <defs>
            <clipPath id="clip0_171_78">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}