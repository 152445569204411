import * as React from "react"
import Page from "../components/page";
import {OrangeFooter, OrangeHeader, OrangeSignUpBlock} from "./orange";

const problems = `Association software is not designed for hub-based associations with volunteer leaders.
All-in-one solutions have clunky interfaces and are not great at anything.
Too many tools are costly,hard to manage, and lack proper integration.
A lot of consumer and b2b software (slack, whatsapp) is not made for associations, leaving major gaps.
Popular social apps (facebook/insta/twitter) are not ideal for associations either.
Most platforms lack customization of look & feel plus unique data needs.`;

function Block({col_span = "col-span-2", future, title, children}) {
    return <div className={`${col_span} bg-[#F4EDEA] min-h-[8rem] ${future?"opacity-70":""} text-lg text-gray-600 p-4 rounded-lg`}>
        <span className="font-bold text-gray-900">{title}</span>{future&&<span>&nbsp;&nbsp;(future)</span>} {children}
    </div>
}

const features = [
    {
        title: "Portal",
        description: "",
    },
    {
        title: "Search",
        description: "",
    },
    {
        title: "API",
        description: "",
    },
    {
        title: "Directory",
        description: "",
    },
    {
        title: "Calendar",
        description: "",
    },
    {
        title: "Notifications",
        description: "",
    },
    {
        title: "Forum",
        description: "",
    },
    {
        title: "Notes",
        description: "",
    },
    {
        title: "Apps",
        description: "",
    },
    {
        title: "Rules",
        description: "",
    },
    {
        title: "Flows",
        description: "",
    },
    {
        title: "Polls",
        future: true,
        description: "",
    },
    {
        title: "Gates",
        future: true,
        description: "",
    },
    {
        title: "Reminders",
        future: true,
        description: "",
    },
    {
        title: "Lists",
        future: true,
        description: "",
    },
    {
        title: "Voting",
        future: true,
        description: "",
    },
    {
        title: "Reports",
        future: true,
        description: "",
    },
    {
        title: "Payments",
        future: true,
        description: "",
    },
    {
        title: "Policies",
        future: true,
        description: "",
    },
    {
        title: "Badges",
        future: true,
        description: "",
    },
];

export default function AboutUsPage() {
    return (
        <Page orange footer={false}>
            <div className="max-w-prose px-5 mx-auto pb-32">
                <OrangeHeader/>
                <div className="space-y-2 pb-20" id="problems">
                    <p className="text-xl font-medium leading-relaxed whitespace-pre-line">
                        Here's a list of the fundamental problems with community software we're tackling:
                    </p>
                    <ul className="text-lg pl-6 font-medium space-y-2 leading-relaxed whitespace-pre-line list-disc">
                        {problems.split("\n").map((c, i) => <li key={i}>{c}</li>)}
                    </ul>
                </div>
                <div id="features">
                    <p className="text-xl font-medium leading-relaxed whitespace-pre-line">
                        We strive to build elegant, end-to-end features and seamless integrations for your community, powered by our proprietary data model and community sync engine.
                    </p>

                    <div className="grid gap-4 grid-cols-6 py-4">
                        <Block col_span="col-span-6" title="Core Community Architecture">
                            with members, leaders, and spaces.
                        </Block>
                        {features.map((f, i) => <Block key={i} future={f.future} title={f.title}>{f.description}</Block>)}
                    </div>
                </div>
                <OrangeSignUpBlock />
                <OrangeFooter/>
            </div>
        </Page>
    )
};